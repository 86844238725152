.video {

    &__lead {
        text-align: center;
        font-size: 20px;
        font-weight: 200;
        margin-bottom: 50px;
    }

    &__item {
        text-align: center;
    }
}
.prices {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 150px;

    &__head {
        margin-bottom: 20px;
    }

    &__card {
        background-color: rgba(255, 255, 255, 0.70);
        margin-bottom: 20px;
    }
    
    &__card-tarif {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 32px;
    }

    &__card-tarif-title {
        color: #000;
    }

    &__card-tarif-icon {
        width: 32px;
        height: 32px;
    }

    &__card-title {
        color: #000;
        min-height: 48px;
    }

    &__card-subtitle {
        margin-bottom: 20px;
    }

    &__card-parametr-head {
        margin: 0;
        padding: 0;
    }
    
    &__card-parametr-value {
        color: #000;
    }

    &__card-price-head {
        margin: 0;
        padding: 0;
    }

    &__card-price-value {
        font-size: 18px;
        color: #333;
        font-weight: 600;
    }

    &__card-price-old {
        text-decoration: line-through;
        font-weight: 300;
    }

    &__card-text {
        color: #000;
        margin-bottom: 20px;
    }

    &__card-footer {
        position: relative;
        color: #000;
    }

    &__card-tarif-hit {
        color: #c90887;
        font-weight: 600;
        border: 1px solid #c90887;
        padding: 6px;
    } 

    &__hit-label {
        position: absolute;
        top: 30px;
        right: 20px;
        background: #c90887;
        padding: 6px;
        border-radius: 2px;
        color: #fff;
        font-size: 14px;
    }

    &__timer {
        margin-bottom: 20px;
    }

}
.comments {

    margin-top: 180px;
    margin-bottom: 150px; 

    &__head { 
        margin-bottom: 50px;
    }

    &__item {
        background-color: rgba(255, 255, 255, 0.70);
    }

    &__item-img {
        width: auto;
        border-radius: 50%;
        padding: 1.25rem;
    }

    &__item-title {
        color: #333;
    }

    &__item-text {
        color: #333;
    }

    &__video {
        margin-top: 50px;
    }

    &__video-head {
        margin-bottom: 20px;
    }

    &__video-item {
        margin-bottom: 20px;
    }

}
.contacts {


  &__item {
    background-color: rgba(255, 255, 255, 0.70);
    padding: 16px 10px;
    border-radius: 3px;
  }

  &__link {

  }
}
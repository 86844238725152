.utp {
    //height: 100%;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;

    &__content {
        margin-top: 100px;
    }

    &__lead {
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    &__head {
        font-size: 46px;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
        }
    }

    &__desc {
        font-size: 18px;
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    &__btn {
        float: left;
    }

    &__play-video {
        display: inline-block;
        color: #fff;
        margin-left: 30px;
        transition-duration: .3s;

        &:hover {
            color: #fff;
            text-decoration: none;
            transform: scale(1.1);
            transition-duration: .3s;
        }
    }

    &__play-img {
        margin-right: 10px;
    }

    &__image {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }


}  

@keyframes blinking {
    0%    { opacity: 0; }
    50%   { opacity: 0; }
    51%   { opacity: 1; }
    100%  { opacity: 1; }
  }
  .change-text:after {
    content: '_';
    animation: blinking 1.2s infinite;
    
  }
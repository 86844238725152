.questions {
    margin-top: 180px;
    margin-bottom: 250px; 

    &__head {
        margin-bottom: 50px;
    }

    &__card-text {
        color: #333;
    }
}
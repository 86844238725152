.about {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 150px;

    @include media-breakpoint-down(sm) {
        margin-top: 100px;
    }

    &__head {
        margin-bottom: 50px;
    }

    &__card-head {
        margin: 20px 0;


    }

    &__card {
        background-color: rgba(255, 255, 255, 0.70);
        margin-bottom: 20px;
    }

    &__card-title {
        color: #333;
        font-weight: 500;
        margin-bottom: 16px;
        min-height: 72px;
    }


}

.fp-slidesNav {
    color: #fff;
}
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/_modal.scss";
@import "partials/normalize";
@import "partials/variables";
@import "partials/sprite";
@import "btn";
@import "blocks/header.scss";
@import "blocks/utp";
@import "blocks/about";
@import "blocks/prices";
@import "blocks/comments.scss";
@import "blocks/video.scss";
@import "blocks/questions.scss";
@import "blocks/contacts.scss";
@import "blocks/footer.scss";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900&subset=cyrillic');


h1, h2, h3, h4, p {
    padding: 0;
    margin: 0;
}

p {
    margin-bottom: 20px;
}

body {
    color: #fff;
    height: 100vh;  
    background: #23136A;
    background-image: url('../img/main-bg.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Roboto', sans-serif;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: radial-gradient(top left, rgba(0, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%);
        background-position: 0 0;

    }
}

.mutted {
    color: rgba(255,255,255,0.50);
}

#fp-nav {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#fp-nav>ul>li>a>span {
    background: #fff !important;
}

 .fp-prev {
     left: 70px !important;

     @include media-breakpoint-down(sm) {
       left: 10px !important;
     }
 }
 
 .fp-next {
     right: 70px !important;

     @include media-breakpoint-down(sm) {
       right: 10px !important;
     }
 }

 .etNumber {
   width: 16px;

 }
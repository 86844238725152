.icon {
    font-size: 10px;
}

.icon:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("../../../../dest/img/sprites/sprite.svg");
    background-repeat: no-repeat;
    background-size: 51.2001em 208.2em;
}

.no-svg .icon:before {
    background-image: url("../../../../dest/img/sprites/sprite.png");
}



  .icon.connection:before {
      background-position: 0em 0em;
      width: 51.2em;
      height: 51.2em;
  }




  .icon.conversation:before {
      background-position: 0em -51.2em;
      width: 51.2em;
      height: 51.2em;
  }




  .icon.expensive:before {
      background-position: 0em -102.4em;
      width: 51.2em;
      height: 51.2em;
  }




  .icon.thumbs-up:before {
      background-position: 0em -153.6em;
      width: 51.2001em;
      height: 51.2001em;
  }




  .icon.vk:before {
      background-position: 0em -204.9em;
      width: 2.6em;
      height: 1.5em;
  }




  .icon.youtube:before {
      background-position: 0em -206.4em;
      width: 2.6em;
      height: 1.8em;
  }






.header {
    position: fixed;
    height: 84px;
    display: block;
    width: 100%;
    padding: 15px 0;
    z-index:9;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        pointer-events: none;
        display: flex;
        align-items: center;
    }

    &__logo-img {
        width: 48px;
        margin-right: 18px;

        
    }

    &__logo-desc {
        text-transform: uppercase;
        font-weight: 500;

        & span {
            display: block;
            text-transform: none;
            font-weight: 300;
            
        }
    }

    &__menu {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__nav {
        list-style: none;
        margin-bottom: 0;
    }

    &__nav-item { 
        color: #fff;
        float: left;
        margin-left: 20px;
    }

    &__nav-link {
        color: #fff;
        transition-duration: .3s;

        &:hover {
            color: rgba(255,255,255,0.70);
            transition-duration: .3s;
            text-decoration: none;
        }
    }

    &__btn {
        display: flex;
        margin-left: 20px;
    }

    &__fill {
        background: #273b87;
        transition-duration: .5s;
    }
} 
.btn-primary {
    display: block;
    color: #fff;
    background: #C90887;
    border-radius: 4px;
    padding: 10px 20px;
    transition-duration: .3s;

    &:hover {
        background: darken(#C90887, 10%);
        text-decoration: none;
    }

}

.btn-bordered  {
    display: block;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 20px;
    transition-duration: .3s;

    &:hover {
        color: #fff;
        text-decoration: none;
        transform: scale(1.1);
        transition-duration: .3s;
    }
}
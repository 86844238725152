.footer {
    position: fixed;
    background: transparent;
    display: block;
    z-index: 9;
    height: 60px;
    padding: 10px 0;
    width: 100%;
    bottom: 0;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__menu {
        list-style: none;
        padding: 0;
    }

    &__menu-item {
        float: left;
        margin-right: 10px;
    }

    &__menu-link {
        color: rgba(255,255,255,0.50);
        margin-right: 20px;
        transition-duration: .3s;

        &:hover {
            color: #fff;
            text-decoration: none;
            transition-duration: .3s;
        }
    }

    &__menu-icon {
        width: 22px;
        height: 22px;
    }

    &__social-link {
        margin-left: 20px;
    }

}